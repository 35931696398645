import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import HoverPanImage from './HoverPanImage';

const Image = ({ imgName, imgLabel, link }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(placeholder: BLURRED, width: 480)
              fluid {
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName.split('.')[0] === imgName
      );
      if (!image) {
        return null;
      }
    //  console.log('data',image)
      //style={{maxWidth:"30%", minWidth: "25%",}}
      return <HoverPanImage fluid={image.node.gatsbyImageData} label={imgLabel} link={link} />;
    }}
  />
);

function ImageCarrusell({ projects, large } )  {
  const css = large ? "carusell-container carusell-large": "carusell-container";
    // console.log('proyecto', projects);
//    const images = ['IMG_5120', 'IMG_5180-2', 'IMG_5242-4', 'IMG_5253'];
    return (
      <div className={css} >
        { projects.map( p => {
            const { node } = p;
            //console.log('proyecto', node);
            return node ? <Image key={node.id} imgName={node.cover} imgLabel={node.seccionTitle} link={`/${node.id}`}  /> : '';
        })
        }
      </div>
    );
  }


ImageCarrusell.propTypes = {
  projects: PropTypes.array
};

export default React.memo(ImageCarrusell);
