import { useState, useEffect } from 'react';
 


export function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
      //console.log(ref);
      let observer;
      if (typeof window !== 'undefined') {
        observer = new IntersectionObserver(([entry]) =>
          setIntersecting(entry.isIntersecting)
        );
      }
      
      try {
        observer.observe(ref.current);
        // Remove the observer as soon as the component is unmounted
      } catch (e) {
        console.log(e);
      }

      return () => {
        observer.disconnect();
      };
    }, []);


  return isIntersecting;
}



export  function useOnScreenThresh(ref) {
  const [isIntersecting, setIntersecting] = useState(false);


  useEffect(() => {
    //console.log(ref);
    let observer
    if (typeof window !== 'undefined') {
      observer = new IntersectionObserver(([entry]) => {
        //console.log(entry);
        setIntersecting(entry.isIntersecting)
      }
        ,
        {rootMargin: "0px 0px 200px 0px",
        threshold: 0.25
      },
      );
    }
    try {
      observer.observe(ref.current);

      // Remove the observer as soon as the component is unmounted
    } catch (e) {
      console.log(e);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
