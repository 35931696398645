import React,{useRef, useState, useEffect}  from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import {useSpring, animated, config} from 'react-spring';
import { GatsbyImage } from "gatsby-plugin-image"
import {useOnScreenThresh} from '../utils/useOnScreen';

let bgCover='';

const Image = ({ imgName, cover = '' }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              gatsbyImageData(placeholder: BLURRED, width: 1024)
              fluid{
                originalName
              }
            }
          }
        }
      }
    `}
  render = { data => {
      const image = data.allImageSharp.edges.find(
        edge => edge.node.fluid.originalName.split('.')[0] === imgName
      );
      if (!image) {
        return null;
      }
      if(cover === imgName) {
        bgCover = image.node.fluid.src;
      }
      return <GatsbyImage image={image.node.gatsbyImageData} alt={imgName}/>;
    }}
  />
);

function DetailImageCarrusell({ album , cover }) {

  const ref = useRef();
  const isVisible = useOnScreenThresh(ref);
  const [items, setItems] = useState(album.slice(0, 3));
    //const { album , cover } = props;
     // console.log('proyecto', album , cover );
  const style = useSpring({
      config: config.molasses,
      from: { number: 0, opacity: 0.5},
      to: { number: 100, opacity: 1 }
  });



  useEffect( () => {
    //console.log({isVisible, prendas, orden})
      if(isVisible) {
        const index = items.length
        setItems([...items, ...album.slice(index , index + 3)])
      }
    }  
  ,[isVisible]);

    return (<>
      <animated.div  className="carusell-container" style={{ width:`${style.number}%`, opacity: `${style.opacity}`}}>
        { items.map(p => { return  <Image key={p} imgName={p} cover={cover} /> ; })}
      </animated.div>
      <div ref={ref}></div>
      </> 
    );
  
}

DetailImageCarrusell.propTypes = {
  album: PropTypes.array,
  cover: PropTypes.string
};

export default React.memo(DetailImageCarrusell);
