import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import Container from "../components/Container";
import Gallery from '../components/Gallery'

const Page = ({ pageContext }) => {

  const { id, seccionTitle, description, cover, album, category } = pageContext;
  return (
    <Layout >
    <SEO title={seccionTitle} />
      <Container  >
        <div className="">               
                    <Gallery
                    album={album}
                    title= {`/${seccionTitle}`}
                    cover={ cover}
                    description={description}
                    isDetail={true}
                    category={category}
                    classCss={'align-left padding-left-1 link'}
                    width={60}
                    />

        </div>
      </Container>
    
    </Layout>
  );
};
export default Page;
