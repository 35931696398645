import React, { useRef } from 'react';

//import Img from 'gatsby-image';
import { navigate } from "gatsby"

import { GatsbyImage } from "gatsby-plugin-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const HoverPanImage = ({ fluid, label, link ='/Home' }) => {
  const mousemove = e => {
    e.preventDefault();
    let clientRect = e.target.getBoundingClientRect();
    let threshold = 1; //17
    //console.log('mousemove target', e.target); // img
    /* console.log(
      "mousemove target .childNodes",
      e.target.parentNode,
      "mousemove target .children 2",      e.target.parentNode.parentNode //container div
    )
*/
    if (
      (clientRect.right - e.pageX > threshold ||
        clientRect.left - e.pageX > threshold) &&
      (clientRect.top - e.pageY > threshold ||
        (clientRect.bottom - e.pageY > threshold && false))
    ) {
      let clientRect = e.target.getBoundingClientRect();
      let parent = e.target.parentNode.parentNode;

      let xmove = ((e.pageX - clientRect.left) / clientRect.width) * 100;
      let ymove = ((e.pageY - clientRect.top) / clientRect.height) * 100;
      parent.style.transform = 'scale(1.1)';
      parent.style.transformOrigin = xmove / 3 + '% ' + ymove / 3 + '%';
      /*  e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.position =
        'fixed';
      e.target.parentNode.parentNode.parentNode.parentNode.parentNode.style.left =
        ymove + '%';*/
      let child = e.target; //e.target
      child.style.position = 'absolute'; //'fixed';
      child.style.left = -clientRect.width / 2 + (-1 * xmove) / 8 + 'px';
      child.style.top = -clientRect.height / 2 + (-1 * ymove) / 8 + 'px';
    }
  };

  const mouseout = e => {
    e.preventDefault();
    //console.log('mouseout target', e.target);
    let clientRect = e.target.getBoundingClientRect();
    let threshold = 1; //17

    if (
      (clientRect.right - e.pageX > threshold ||
        clientRect.left - e.pageX > threshold) &&
      (clientRect.top - e.pageY > threshold ||
        clientRect.bottom - e.pageY > threshold)
    ) {
      // let clientRect = e.target.getBoundingClientRect();
      let parent = e.target.parentNode.parentNode;
      parent.style.transform = 'scale(1)';
    }
  };

  const mouseover = e => {
    e.preventDefault();
    //console.log('mouseover target', e.target);
    let parent = e.target.parentNode.parentNode;
   // parent.style.transform = 'scale(1.1)';
  };

  const inputEl = [];

  return (
    <div
      ref={elem => inputEl.push(elem)}

      onClick={e => navigate(link)}
   

      onMouseMove={e => /*mousemove(e)*/ e}
      onMouseOut={e => mouseout(e)}
      onMouseOver={e => mouseover(e)}
      className="tiles"
      style={{
        zIndex: 100,
        transition: 'transform .5s ease-out'
      }}
    >
      <span
        style={{
          
        position: 'absolute',
        transformOrigin: '0 0',
        transform :'rotate(-90deg)',
        bottom:0,
        left: 0,
        textShadow: '2px 1px #000000de',
        backgroundColor: '#0000001a' ,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth:'180px',
        maxHeight:'180px',
        color:'white',
        width:'100%',
        textAlign: 'start',
        zIndex: 1000
        }}
      >
        {label}
      </span>
      <GatsbyImage
        className="tile"
        image={fluid}
        alt={label}
      />
    </div>
  );
};

export default React.memo(HoverPanImage);
