import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from "gatsby"
import {useSpring, config, animated} from 'react-spring'

import ImageCarrusell from './ImageCarrusell.jsx';
import DetailImageCarrusell from './DetailImageCarrusell'

function Gallery(props) {
    const { projects = [], album =[], cover = '', title, description, classCss = '', isDetail = false , 
        category, width = 45, minHeight = 280, large = false , right = false, small = false} = props;
    const cssClass = 'main-section align-center ' + classCss + (right ? ' right':'' );
    const spring = useSpring({
      config: config.gentle ,
      from: { number: 0, opacity: 0.5},
      to: { number: width * 10 , opacity: 1, ratio: 100/width} 
    });


    return (
    <>
        <animated.div className={cssClass} style={{width: spring.number , opacity:spring.opacity, /*minHeight:`${minHeight}px`,*/ zIndex: '-1' , position: large ? 'absolute' : 'relative'}}>
          { isDetail &&    
            <div>
              <h1 className={"title " + classCss}  onClick={e => navigate(`/Proyectos#${category}`)}> {category}</h1>
            </div>  
          }

          <div className={isDetail ? "detail": ""}  style={{width:`${spring.number * spring.ratio}%`, height:`${spring.number * spring.ratio}%`, opacity:spring.opacity}}>
            <h2 className="title"> {title}</h2>
            {description  && <p> {description} </p>}
            { isDetail ?  
              <DetailImageCarrusell album={album} cover={cover} /> : 
              <ImageCarrusell projects={small ? projects.slice(0,1) : projects} large={large} />
            }
          </div>
        </animated.div>
    </>
    );
  
}

Gallery.propTypes = {
  projects: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  classCss : PropTypes.string,
  isDetail : PropTypes.bool,
  category : PropTypes.string,
};

export default Gallery;
